










import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import { Message } from "element-ui";
import api from "@/api";
import {imageUrl2} from '@/utils/common'

@Component({
    name: "uploadNew",
    components: {}
})

export default class extends Vue {
    // eslint-disable-next-line @typescript-eslint/ban-types
    // @Prop({ default: true }) private dataAuth!: {};
    @Prop({ default: {} }) private model!: any;
    @Prop({ default: {image_name: '', image_url: ''} }) private fileList!: any;

    baseUrl = imageUrl2
    isShowLoading = false

    dataAuth = {}
    handleAvatarSuccess(res: any, file: any) {
        this.fileList.image_name = file.name;
        this.fileList.image_url = res.data;
        this.isShowLoading = false
        this.$emit('getData', res.data)
    }

    beforeAvatarUpload(file: any) {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
            this.$message.error("上传图片只能是 JPG/PNG 格式!");
        }
        if (!isLt2M) {
            this.$message.error("上传图片大小不能超过 2MB!");
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _that = this;
        return new Promise(function (resolve, reject) {
            if (isJPG && isLt2M) {
                api
                    .post("J31112", {
                        fileName: file.name,
                        softLink: ''
                    })
                    .then((data) => {
                        _that.isShowLoading = true
                        _that.dataAuth = data;
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    });
            } else {
                reject(false);
            }
        });
    }

}
