
































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";

import uploadStaff from "@/components/uploadStaff.vue";
import {checkPhone, imageUrl2, checkEmail} from '@/utils/common'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

@Component({
    components: {
        uploadStaff
    }
})
export default class AUser extends Vue {
    baseUrl = imageUrl2
    // 字典
    config = store.state.system.config;
    // 表格数据
    tableData = [];
    // 加载状态
    loading = false;
    // 总条数
    total = 0;
    // 搜索条件
    search = {
        companyId: "",
        keyword: '',
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };
    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.search.companyId = this.$route.query.companyId as string
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post("J31106", {
                ...this.search
            })
            .then((data) => {
                this.total = data.total;
                this.tableData = data.list;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    peopleInfo: any = {}
    model: any = {
        OSSAccessKeyId: "",
        signature: "",
        policy: "",
        key: "",
        callback: "",
        file: "",
        success_action_status: ''
    };
    fileList: any = {
        image_url: '',
        image_name: ''
    }
    fileList2: any = {
        image_url: '',
        image_name: ''
    }
    getData(item: any) {
        this.peopleInfo.headPicture = item
    }
    getData2(item: any) {
        this.peopleInfo.wxCode = item
    }
    toAdd() {
        this.fileList = {
            image_url: '',
            image_name: ''
        }
        this.fileList2 = {
            image_url: '',
            image_name: ''
        }
        this.peopleInfo = {}
        this.editDialog = true
    }
    sure() {

        this.peopleInfo.companyId = this.$route.query.companyId
        if(!this.fileList.image_url) {
            this.$message.warning('头像不能为空')
            return
        }
        if(!this.peopleInfo.employeeName) {
            this.$message.warning('员工姓名不能为空')
            return
        }
        if(!this.peopleInfo.employeePost) {
            this.$message.warning('员工职位不能为空')
            return
        }
        if(!this.peopleInfo.employeePhone) {
            this.$message.warning('联系方式不能为空')
            return
        }
        if (!checkPhone(this.peopleInfo.employeePhone)) {
            return;
        }
        if(this.peopleInfo.email) {
            if (!checkEmail(this.peopleInfo.email)) {
                return;
            }
        }
        if(!this.fileList2.image_url) {
            this.$message.warning('二维码不能为空')
            return
        }
        this.loading = true
        api.post(this.peopleInfo.id ? 'J31110' : 'J31107', this.peopleInfo).then(res => {
            this.editDialog = false
            this.searchTable()
        }).finally(() => {
            this.loading = false
        })
    }

    lookDialog = false
    detailInfo = {}
    toLook(id: any) {
        api.post('J31108', {id}).then(res => {
            api.post('J31102', {id: this.$route.query.companyId}).then(data => {
                this.detailInfo = data
                this.peopleInfo = res
                this.lookDialog = true
            })

        })

    }

    editDialog = false
    edit(id: any) {
        api.post('J31109', {id}).then(res => {
            this.fileList = {
                image_url: res.headPicture,
                image_name: res.headPicture
            }
            this.fileList2 = {
                image_url: res.wxCode,
                image_name: res.wxCode
            }
            this.peopleInfo = res
            this.editDialog = true
        })

    }

    del(row: any) {
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            api.post('J31111', {id: row.id}).then(res => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.searchTable()
            })

        })
    }

    activated() {
        let { companyId } = this.$route.query
        if(companyId) {
            this.search.companyId = companyId.toString()
        }


        this.searchTable();
    }
}
